import React from 'react';
import '../../Assets/css/Card.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

class Card extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    students: [],
    loading:true
  }

  async componentDidMount(){
    const res = await axios.get('http://test2.ellegance.sk/api/main_page.php');
      if(res.status === 200){
        console.log(res.data)
        this.setState({
          card:res.data,
          loading:false
        });
      }
  }
  
  
  render() {
    if(this.state.loading)
    {
        return(<div></div>)
    }
    else
    {
      return (
        <div className="row">
          {this.state.card.map((card, index) => (
            <div className="cardd" key={card.id}>
              <div className="top">
                <img className="imagess" src={"../../images/main_page/"+card.img} alt=""/>
              </div>
              <div className="bottom">
                <h3>{card.title}</h3>
                <p>{card.content}</p>
                <a href={card.url} className="btn">{card.button} <i className="fa-solid fa-angles-right"></i></a>
              </div>
            </div>
          ))}
        </div>
      );
    }
  }
}

//TODO: slideData: change to api.get("From db names")
export default <Card/>;
